/* 
        .bottom-menu{
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;    
                    
        } */

.bottom-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  align-items: flex-start !important;
}

.menu-link {
  text-decoration: none;
  color: #333;
  font-size: small;
  text-decoration: none;
}
